import { CustomTimer } from '@tunein/web-utils';
import PropTypes from 'prop-types';
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { transitionStreamName } from 'src/common/constants/boost';
import {
  ICY_METADATA_ENABLED,
  PLAYER_QUALIFIED_TUNE_SECONDS,
  WEB_MEDIA_PLAYER_ENABLED,
  WEB_TUNER_AUTOPLAY_ENABLED,
  WEB_TUNER_INSTREAM_LOGGING_ENABLED,
  WEB_TUNER_SHOUTCAST_EXTRA_TIMEOUT,
  WEB_TUNER_STREAM_RETRY_LIMIT,
  WEB_TUNER_STREAM_TIMEOUT,
} from 'src/common/constants/experiments/config';
import { mintSingleton } from '../../../client/mint';
import { tunerSingleton } from '../../../client/tuner';
import { logClientError, logFeatureActivity } from '../../actions/logging';
import {
  endIntro,
  endOutro,
  primePlayerForAutoPlay,
} from '../../actions/player';
import { reportPlayerError } from '../../actions/reporting';
import * as TunerActions from '../../actions/tuner';
import feature from '../../constants/analytics/categoryActionLabel/feature';
import { AB_TEST_IDS } from '../../constants/experiments';
import {
  FORCE_HTTPS_STREAMS,
  HOWLER_ENABLED,
  LISTEN_TIME_REPORT_INITIAL_INTERVAL_REPEAT,
  LISTEN_TIME_REPORT_INITIAL_INTERVAL_SECONDS,
  LISTEN_TIME_REPORT_INTERVAL_SECONDS,
  REJECTED_STREAMS_REASON_BLOCKLIST,
  WEB_TUNER_MAX_PRELOAD_STREAMS,
} from '../../constants/experiments/player';
import {
  BOOST_AUDIO_INTRO_PLAYER_TITLE,
  BOOST_AUDIO_OUTRO_PLAYER_TITLE,
} from '../../constants/localizations/boost-aka-switch';
import { LocationAndLocalizationContext } from '../../providers/LocationAndLocalizationProvider';
import { selectIsMobile } from '../../selectors/app';
import {
  selectBoostIntroAudio,
  selectBoostIntroPlaySessionCap,
  selectBoostOutroAudio,
  selectBoostOutroPlaySessionCap,
  selectExperiment,
} from '../../selectors/config';
import { selectIsUserSubscribed } from '../../selectors/me';
import { selectAutoPlayReady, selectListenId } from '../../selectors/player';
import { isProfile } from '../../utils/guideItemTypes';
import isServer from '../../utils/isServer';
import shouldShowPlayer from '../../utils/playerStatus/shouldShowPlayer';
import MidrollHook from '../ads/MidrollHook';
import Player from './Player';
import BoostIntroPopover from './boost/BoostIntroPopover';

class WithPlayer extends Component {
  static propTypes = {
    qualifiedTuneSeconds: PropTypes.number,
    timeout: PropTypes.number.isRequired,
    retryLimit: PropTypes.number.isRequired,
    maxPreloadStreams: PropTypes.number.isRequired,
    shoutcastExtraTimeout: PropTypes.number.isRequired,
    isInstreamLoggingEnabled: PropTypes.bool.isRequired,
    isAutoPlayEnabled: PropTypes.bool.isRequired,
    isHowlerEnabled: PropTypes.bool,
    abTestIds: PropTypes.string,
    actions: PropTypes.object.isRequired,
    breakpoint: PropTypes.number.isRequired,
    routeProps: PropTypes.object.isRequired,
    showPlayer: PropTypes.bool.isRequired,
    apiClient: PropTypes.object, // Not required since we won't get this on the server.
    history: PropTypes.object.isRequired,
    autoPlayReady: PropTypes.bool,
    isUserSubscribed: PropTypes.bool.isRequired,
    isMobile: PropTypes.bool.isRequired,
    introMp3Url: PropTypes.string.isRequired,
    outroMp3Url: PropTypes.string.isRequired,
    introCap: PropTypes.number.isRequired,
    outroCap: PropTypes.number.isRequired,
    isDiscord: PropTypes.bool.isRequired,
    forceHttpsStreams: PropTypes.bool,
    blockRejectedStreamsPlaybackMap: PropTypes.object,
    periodicIntervalSeconds: PropTypes.number,
    initialPeriodicIntervalSeconds: PropTypes.number,
    initialPeriodicIntervalRepeat: PropTypes.number,
    isWebMediaPlayerEnabled: PropTypes.bool,
    isIcyMetadataEnabled: PropTypes.bool,
  };

  static contextType = LocationAndLocalizationContext;

  #qualifiedTuneTimer;

  #isQualified = false;

  eventMap = {};

  constructor(props, context) {
    super(props, context);

    this.nextPlayButtonRef = createRef();

    this.state = { isReady: false };

    // Setup the web-tuner and event listeners here, but only on the client.
    if (!isServer()) {
      this.init();
    }
  }

  componentDidMount() {
    this.deregisterHistoryListener = this.props.history.listen(
      async (location) => {
        const { locationPathname } = this.state;

        if (locationPathname && locationPathname === location.pathname) {
          return;
        }

        await tunerSingleton.readyPromise;

        tunerSingleton.instance?.scheduleDestroyPreloads();
        this.setState({ locationPathname: location.pathname });
      },
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      actions,
      autoPlayReady,
      listenId,
      isAutoPlayEnabled,
      routeProps,
      isDiscord,
    } = this.props;
    const { isReady } = this.state;

    if (!prevState.isReady && isReady) {
      const guideId = routeProps?.guideContext?.guideId;
      const isProfileRoute = isProfile(guideId);

      if (isAutoPlayEnabled && !isProfileRoute && !isDiscord) {
        actions.primePlayerForAutoPlay();
      }

      this.registerListeners();
      actions.setTunerReady();
    }

    if (!prevProps.autoPlayReady && autoPlayReady) {
      const { actions: featureActions, labels: featureLabels } = feature;
      actions.logFeatureActivity(
        featureActions.autoPlay,
        featureLabels.showMiniPlayer,
      );
      return;
    }

    if (prevProps.listenId !== listenId) {
      tunerSingleton.instance?.setListenId(listenId);
    }
  }

  componentWillUnmount() {
    this.deregisterHistoryListener?.();

    if (!isServer()) {
      this.removeListeners();
    }
  }

  async init() {
    // We need to load jplayer before web-tuner is set up, as it's required to be injected into
    // the jquery namespace before stream player is setup, so require it here before the tuner
    // setup.
    require('jplayer'); // eslint-disable-line global-require

    const playerElement = document.createElement('div');
    playerElement.id = 'player';
    document.body.appendChild(playerElement);

    const {
      timeout,
      retryLimit,
      maxPreloadStreams,
      introMp3Url,
      outroMp3Url,
      introCap,
      outroCap,
      shoutcastExtraTimeout,
      isInstreamLoggingEnabled,
      isHowlerEnabled,
      abTestIds,
      apiClient,
      actions,
      forceHttpsStreams,
      blockRejectedStreamsPlaybackMap,
      periodicIntervalSeconds,
      initialPeriodicIntervalSeconds,
      initialPeriodicIntervalRepeat,
      isWebMediaPlayerEnabled,
      isIcyMetadataEnabled,
    } = this.props;
    const { getLocalizedText } = this.context;

    const tunerConfigs = {
      timeout,
      retryLimit,
      maxPreloadStreams,
      boostConfig: {
        introMp3Url,
        outroMp3Url,
        introCap,
        outroCap,
        introTitle: getLocalizedText(BOOST_AUDIO_INTRO_PLAYER_TITLE),
        outroTitle: getLocalizedText(BOOST_AUDIO_OUTRO_PLAYER_TITLE),
      },
      shoutcastExtraTimeout,
      isInstreamLoggingEnabled,
      isHowlerEnabled,
      abTestIds,
      forceHttpsStreams,
      blockRejectedStreamsPlaybackMap,

      // hasNativeHlsSupport: Instruction passed to the web-tuner that is used
      // whether to use JPlayer and the native HTML Media Element browser implementation
      // to play HLS stream. If there is no native support, then the web-tuner will
      // use an HLS media player library to play HLS format streams.
      hasNativeHlsSupport: false,

      // resources: The player library asset urls that the web-tuner requires to load a
      // given media player library.
      resources: {
        fetchHlsJsLibrary: () =>
          import(/* webpackChunkName: "hlsjs" */ 'hls.js').then(
            (module) => module.default,
          ),
      },
      listenTimeConfig: {
        periodicIntervalSeconds,
        initialPeriodicIntervalSeconds,
        initialPeriodicIntervalRepeat,
      },
      isWebMediaPlayerEnabled,
      isIcyMetadataEnabled,
    };

    await tunerSingleton.init(apiClient, playerElement, tunerConfigs);

    const { events } = tunerSingleton.instance || {};
    this.eventMap = {
      [events.meta]: actions.meta,
      [events.connecting]: actions.connecting,
      [events.loading]: actions.loading,
      [events.paused]: this.onPaused,
      [events.stopped]: this.onStopped,
      [events.playing]: this.onPlaying,
      [events.nowPlayingChanged]: actions.nowPlayingChanged,
      [events.mediaEnded]: this.onMediaEnded,
      [events.streamFailed]: actions.streamFailed,
      [events.stationFailed]: actions.stationFailed,
      [events.waiting]: actions.waiting,
      [events.htmlStreamLoaded]: actions.htmlStreamLoaded,
      [events.evaluateStreamStart]: actions.evaluateStreamStart,
      [events.positionChanged]: actions.positionChanged,
      [events.nextTune]: this.onNextTune,
      [events.instreamAd]: this.onInstreamAd,
      [events.error]: this.onError,
      [events.playerSelected]: actions.playerSelected,
      [events.attemptingPlayback]: actions.clearPlayerNameList,
      [events.initiateIntro]: actions.initiateIntro,
      [events.initiateOutro]: actions.initiateOutro,
      [events.endIntro]: actions.endIntro,
      [events.endOutro]: actions.endOutro,
    };

    this.setState({ isReady: true });
  }

  onStopped = () => {
    this.#qualifiedTuneTimer?.clear();
  };

  onPaused = () => {
    if (!this.#isQualified) {
      this.#qualifiedTuneTimer?.pause();
    }
  };

  onError = (data) => {
    const { actions } = this.props;
    actions.reportPlayerError(data?.context);
    actions.logClientError(data);
    this.#qualifiedTuneTimer?.clear();
  };

  onPlaying = (stream) => {
    const { actions, qualifiedTuneSeconds } = this.props;

    actions.playing(stream);

    if (this.#isQualified || stream.isBoostTransition) {
      return;
    }

    // Unpause the qualified tune event timer if it's paused
    if (!this.#qualifiedTuneTimer?.isPaused) {
      // Start a new timer for qualified tune event if we haven't played in this session
      this.#qualifiedTuneTimer = new CustomTimer(() => {
        this.#isQualified = true;
        actions.qualifiedTune();
      }, qualifiedTuneSeconds * 1000);
    }

    this.#qualifiedTuneTimer.start();
  };

  onNextTune = (nextTune) => {
    const { actions, history } = this.props;

    if (!tunerSingleton.instance?.listenId) {
      actions.setListenId();
    }

    actions.nextTune(nextTune, history);
  };

  // the tuner selects the player that can handle instream ads based on the stream,
  // when it receives the ad data, it's passed here so that we can pass it to mint
  onInstreamAd(metadata) {
    mintSingleton.instance?.handleInstreamAds(metadata);
  }

  onMediaEnded = (mediaEndedData) => {
    const { actions } = this.props;

    if (mediaEndedData?.isBoostTransition) {
      // NOTE: When intro / outro finishes, do not want to set playerstatus to stopped (via actions.mediaEnded)
      if (mediaEndedData?.transitionStreamName === transitionStreamName.intro) {
        return actions.endIntro();
      }

      if (mediaEndedData?.transitionStreamName === transitionStreamName.outro) {
        return actions.endOutro();
      }
    }

    actions.setListenId(true);
    actions.mediaEnded();
    this.#qualifiedTuneTimer?.clear();
  };

  registerListeners() {
    const keys = Object.keys(this.eventMap);
    keys.forEach((key) => {
      const value = this.eventMap[key];
      tunerSingleton.instance?.on(key, value);
    });
  }

  removeListeners() {
    const keys = Object.keys(this.eventMap);
    keys.forEach((key) => {
      const value = this.eventMap[key];
      tunerSingleton.instance?.off(key, value);
    });
  }

  render() {
    const {
      breakpoint,
      showPlayer,
      isUserSubscribed,
      isMobile,
      isDiscord,
      routeProps,
    } = this.props;

    return showPlayer ? (
      <>
        {!isUserSubscribed && <MidrollHook />}
        {!(isMobile && routeProps.hidePlayerOnMobile) && (
          <>
            <BoostIntroPopover
              breakpoint={breakpoint}
              nextPlayButtonRef={this.nextPlayButtonRef}
            />
            <Player
              breakpoint={breakpoint}
              disablePreroll={routeProps.disablePreroll}
              nextPlayButtonRef={this.nextPlayButtonRef}
              isDiscord={isDiscord}
            />
          </>
        )}
      </>
    ) : null;
  }
}

export const mapStateToProps = (state) => ({
  qualifiedTuneSeconds: selectExperiment(
    state,
    PLAYER_QUALIFIED_TUNE_SECONDS,
    60,
  ),
  autoPlayReady: selectAutoPlayReady(state),
  timeout: selectExperiment(state, WEB_TUNER_STREAM_TIMEOUT),
  retryLimit: selectExperiment(state, WEB_TUNER_STREAM_RETRY_LIMIT),
  maxPreloadStreams: selectExperiment(state, WEB_TUNER_MAX_PRELOAD_STREAMS, 0),
  shoutcastExtraTimeout: selectExperiment(
    state,
    WEB_TUNER_SHOUTCAST_EXTRA_TIMEOUT,
  ),
  isInstreamLoggingEnabled: selectExperiment(
    state,
    WEB_TUNER_INSTREAM_LOGGING_ENABLED,
  ),
  isAutoPlayEnabled: selectExperiment(state, WEB_TUNER_AUTOPLAY_ENABLED),
  isHowlerEnabled: selectExperiment(state, HOWLER_ENABLED),
  abTestIds: selectExperiment(state, AB_TEST_IDS),
  showPlayer: shouldShowPlayer(state),
  isUserSubscribed: selectIsUserSubscribed(state),
  isMobile: selectIsMobile(state),
  listenId: selectListenId(state),
  introMp3Url: selectBoostIntroAudio(state),
  outroMp3Url: selectBoostOutroAudio(state),
  introCap: selectBoostIntroPlaySessionCap(state),
  outroCap: selectBoostOutroPlaySessionCap(state),
  forceHttpsStreams: selectExperiment(state, FORCE_HTTPS_STREAMS),
  blockRejectedStreamsPlaybackMap: selectExperiment(
    state,
    REJECTED_STREAMS_REASON_BLOCKLIST,
  ),
  periodicIntervalSeconds: selectExperiment(
    state,
    LISTEN_TIME_REPORT_INTERVAL_SECONDS,
  ),
  initialPeriodicIntervalSeconds: selectExperiment(
    state,
    LISTEN_TIME_REPORT_INITIAL_INTERVAL_SECONDS,
  ),
  initialPeriodicIntervalRepeat: selectExperiment(
    state,
    LISTEN_TIME_REPORT_INITIAL_INTERVAL_REPEAT,
  ),
  isWebMediaPlayerEnabled: selectExperiment(state, WEB_MEDIA_PLAYER_ENABLED),
  isIcyMetadataEnabled: selectExperiment(state, ICY_METADATA_ENABLED),
});

export const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      logFeatureActivity,
      primePlayerForAutoPlay,
      logClientError,
      endIntro,
      endOutro,
      playing: TunerActions.playing,
      connecting: TunerActions.connecting,
      loading: TunerActions.loading,
      nowPlayingChanged: TunerActions.nowPlayingChanged,
      mediaEnded: TunerActions.mediaEnded,
      streamFailed: TunerActions.streamFailed,
      stationFailed: TunerActions.stationFailed,
      waiting: TunerActions.waiting,
      qualifiedTune: TunerActions.qualifiedTune,
      htmlStreamLoaded: TunerActions.handleHtmlStream,
      nextTune: TunerActions.nextTune,
      evaluateStreamStart: TunerActions.handleStreamEvaluation,
      positionChanged: TunerActions.positionChanged,
      meta: TunerActions.handleMeta,
      playerSelected: TunerActions.playerSelected,
      clearPlayerNameList: TunerActions.clearPlayerNameList,
      setListenId: TunerActions.setListenId,
      initiateIntro: TunerActions.initiateIntro,
      initiateOutro: TunerActions.initiateOutro,
      setTunerReady: TunerActions.setTunerReady,
      reportPlayerError,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(WithPlayer);
